import {
  reportError,
  messages,
  createMediaSrc,
  parseMediaSrc,
} from '@wix/editor-elements-corvid-utils';
import {
  File,
  FileType,
  FileMetaData,
  IFileUploaderImperativeActions,
  IFileUploaderProps,
  FileInfo,
  MediaServerErrorKey,
  GeneralFileUploadErrorKey,
} from '../FileUploader.types';
import {
  ValidationData,
  OnValidateArgs,
  getFileValidityValidationMessage,
  fileTypeToBaseFileType,
} from '../../../core/corvid/inputUtils';

import {
  GeneralErrorTranslation,
  MediaServerErrorKeysTranslationMapping,
} from '../constants';

export type UploadedFileData = {
  media_type: MediaType;
  file_name: string;
  original_file_name: string;
  height?: number;
  width?: number;
  file_output?: {
    image: Array<{
      url: string;
      height: number;
      width: number;
    }>;
  };
  file_input?: {
    duration: number;
  };
};

type ProcessedFileData = UploadedFileData & {
  uri?: string;
  title?: string;
  filename?: string;
  posterUri?: string;
  duration?: number;
};

export type SuccessfulUploadResponse = UploadedFileData | undefined;
export type FailedUploadResponse = {
  error_code: number;
  error_description: string;
  error_info: {
    key: string;
    message: string;
  };
};

type MediaType = 'picture' | 'document' | 'video' | 'music';
type MediaSrcType = 'image' | 'document' | 'video' | 'audio';

const mediaTypeToMediaSrcType: { [key in MediaType]: MediaSrcType } = {
  picture: 'image',
  document: 'document',
  video: 'video',
  music: 'audio',
};

const noFileToUploadMessage = 'No files found';

export const startUploadDeprecationErrorObject = {
  errorCode: -7754,
  errorDescription:
    'startUpload is deprecated, to upload multiple files please use the uploadFiles() function.',
};

export const filesUploadInProgressErrorObject = {
  errorCode: -7755,
  errorDescription: 'files upload in progress.',
};

export const generateNumFilesSelectedErrorObject = (
  numOfSelectedFiles: number,
  numberOfExpectedFiles: number,
) => ({
  errorCode: -7753,
  errorDescription: `There are ${numOfSelectedFiles} files selected, please select up to ${numberOfExpectedFiles}`,
});

export const generalErrorObject = {
  errorCode: -7756,
  errorDescription: 'Unexpected error occurred.',
};

const fileTypeToMediaType = (fileType: FileType, fileName: string) => {
  const mediaTypesTranslation = {
    Image: 'picture',
    Document: 'document',
    Video: 'video',
    Audio: 'music',
  } as const;

  return mediaTypesTranslation[fileTypeToBaseFileType(fileType, fileName)];
};

export const isValidFileType = (_fileType: string) => {
  const fileType =
    _fileType.charAt(0).toUpperCase() + _fileType.slice(1).toLowerCase();
  const validFileTypes = ['Image', 'Document', 'Video', 'Audio', 'Gallery'];
  if (!validFileTypes.includes(fileType)) {
    reportError(
      messages.invalidEnumValueMessage({
        functionName: 'fileType',
        propertyName: 'fileType',
        value: _fileType,
        enum: validFileTypes,
        index: undefined,
      }),
    );
    return false;
  }
  return true;
};

export const getFileDataByType = (
  fileType: FileType,
  uploadedFileData: UploadedFileData,
) => {
  const baseFileType = fileTypeToBaseFileType(
    fileType,
    uploadedFileData.file_name,
  );
  switch (baseFileType) {
    case 'Image':
      return {
        ...uploadedFileData,
        uri: uploadedFileData.file_name,
        filename: uploadedFileData.original_file_name,
      };
    case 'Document':
      return {
        ...uploadedFileData,
        uri: uploadedFileData.file_name,
        filename: uploadedFileData.original_file_name,
      };
    case 'Video':
      const posterImages = uploadedFileData.file_output?.image || [];
      const poster01 = posterImages[1] || { url: '' };
      return {
        ...uploadedFileData,
        uri: uploadedFileData.file_name,
        filename: uploadedFileData.original_file_name,
        posterUri: poster01.url.replace('media/', ''),
        width: poster01.width,
        height: poster01.height,
      };
    case 'Audio':
      return {
        ...uploadedFileData,
        uri: uploadedFileData.file_name,
        filename: uploadedFileData.original_file_name,
        duration: uploadedFileData.file_input?.duration || 0,
      };
    default:
      return uploadedFileData;
  }
};

export const getWixCodeURI = (fileData: ProcessedFileData): string => {
  const mediaSrc = createMediaSrc({
    type: mediaTypeToMediaSrcType[fileData.media_type],
    mediaId: fileData.uri,
    title: fileData.filename,
    height: fileData.height,
    width: fileData.width,
    posterId: fileData.posterUri,
    duration: fileData.duration,
  });

  if (mediaSrc.error || !mediaSrc.item) {
    throw new Error(mediaSrc.error || 'No File Url Generated');
  }

  return mediaSrc.item;
};

export const getFileInfo = (mediaType: MediaType, url: string): FileInfo => {
  switch (mediaType) {
    case 'picture':
      const imageMediaSrc = parseMediaSrc(url, 'image');
      if (imageMediaSrc.error) {
        throw new Error(imageMediaSrc.error);
      }
      return {
        fileUrl: url,
        fileName: imageMediaSrc.mediaId,
        originalFileName: imageMediaSrc.title,
        width: imageMediaSrc.width,
        height: imageMediaSrc.height,
      };
    case 'document':
      const documentMediaSrc = parseMediaSrc(url, 'document');
      if (documentMediaSrc.error) {
        throw new Error(documentMediaSrc.error);
      }
      return {
        fileUrl: url,
        fileName: documentMediaSrc.mediaId,
        originalFileName: documentMediaSrc.title,
      };
    case 'video':
      const videoMediaSrc = parseMediaSrc(url, 'video');
      if (videoMediaSrc.error) {
        throw new Error(videoMediaSrc.error);
      }
      return {
        fileUrl: url,
        fileName: videoMediaSrc.mediaId,
        originalFileName: videoMediaSrc.title,
        width: videoMediaSrc.width,
        height: videoMediaSrc.height,
      };
    case 'music':
      const musicMediaSrc = parseMediaSrc(url, 'audio');
      if (musicMediaSrc.error) {
        throw new Error(musicMediaSrc.error);
      }
      return {
        fileUrl: url,
        fileName: musicMediaSrc.mediaId,
        duration: musicMediaSrc.duration,
        originalFileName: musicMediaSrc.title,
      };
    default:
      throw new TypeError(`Unknown media_type "${mediaType}"`);
  }
};

const getErrorCode = (validity: ValidationData['validity']) => {
  if (validity.fileTypeNotAllowed) {
    return -7751;
  }
  if (validity.fileSizeExceedsLimit) {
    return -7752;
  }
  if (validity.exceedsFilesLimit) {
    return -7753;
  }

  return -1;
};

export const getErrorObject = (
  validationData: ValidationData,
  props: IFileUploaderProps,
) => {
  const errorCode = getErrorCode(validationData.validity);
  const errorDescription =
    errorCode === -1
      ? noFileToUploadMessage
      : errorCode === -7753
      ? generateNumFilesSelectedErrorObject(
          props.value.length,
          props.numFilesLimit,
        ).errorDescription
      : validationData.validationMessage;

  return {
    errorCode,
    errorDescription,
  };
};

export const startFileUpload = async ({
  fileType,
  handlers,
  file,
  onFailedUpload,
  onSuccessfulUpload,
}: {
  fileType: FileType;
  handlers: any;
  file: File;
  onFailedUpload: (uploadResponse: FailedUploadResponse) => void;
  onSuccessfulUpload: (uploadResponse: SuccessfulUploadResponse) => void;
}) => {
  const mediaAuthToken = (await handlers.getMediaAuthToken()) || '';
  const mediaType = fileTypeToMediaType(fileType, file.name);

  const getUploadUrlResponse = await getUploadUrl({
    file,
    mediaAuthToken,
    mediaType,
  });
  const getUploadUrlResponseJson = await getUploadUrlResponse.json();
  if (!getUploadUrlResponse.ok) {
    return onFailedUpload(getUploadUrlResponseJson);
  }

  const uploadFileToMediaResponse = await uploadFileToMediaManager({
    uploadUrl: getUploadUrlResponseJson.upload_url,
    file,
    mediaType,
  });

  if (!uploadFileToMediaResponse.ok) {
    return onFailedUpload(uploadFileToMediaResponse.response);
  }

  return onSuccessfulUpload(uploadFileToMediaResponse.response[0]);
};

const getUploadUrl = ({
  file,
  mediaType,
  mediaAuthToken,
}: {
  file: File;
  mediaType: MediaType;
  mediaAuthToken: string;
}) => {
  const getUrl = `https://files.wix.com/site/media/files/upload/url?media_type=${mediaType}&file_name=${encodeURIComponent(
    file.name,
  )}&mime_type=${encodeURIComponent(file.type)}`;

  return fetch(getUrl, {
    method: 'GET',
    headers: {
      Authorization: `APP ${mediaAuthToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

const uploadFileToMediaManager = ({
  uploadUrl,
  file,
  mediaType,
}: {
  uploadUrl: string;
  file: File;
  mediaType: MediaType;
}): Promise<{
  ok: boolean;
  response: any;
}> => {
  const formData = new FormData();
  formData.append('media_type', mediaType);
  formData.append('file', file);
  formData.append('parent_folder_id', 'visitor-uploads');

  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.onload = () => {
      if (xhr.status !== 200) {
        resolve({
          ok: false,
          response: xhr.response,
        });
      }

      resolve({
        ok: true,
        response: xhr.response,
      });
    };

    xhr.open('POST', uploadUrl);
    xhr.send(formData);
  });
};

export const onValidate = ({
  viewerSdkAPI: api,
  validationDataResult,
}: OnValidateArgs<IFileUploaderProps, IFileUploaderImperativeActions>) => {
  if (
    validationDataResult.type === 'FileUploader' &&
    api.props.uploadStatus === 'Not_Started'
  ) {
    const isInvalidToUpload = Object.entries(
      validationDataResult.validity,
    ).some(
      ([key, value]) => value && key !== 'valid' && key !== 'fileNotUploaded',
    );

    const filesValidationInfo = validationDataResult.filesValidationInfo;
    const newValue: Array<FileMetaData> = api.props.value.map((file, idx) => ({
      name: file.name,
      size: file.size,
      valid: !filesValidationInfo[idx],
      validityInfo: {
        invalidKey: filesValidationInfo[idx],
        invalidInfo: getFileValidityValidationMessage(
          filesValidationInfo[idx],
          file.name,
          api.props.fileType,
        ),
      },
      fileInfo: file.fileInfo,
      uploadStatus: file.uploadStatus,
    }));
    api.setProps({
      value: newValue,
      isInvalidToUpload,
      validationMessage: validationDataResult.validationMessage,
    });
  }
};

export const getNewPromiseResolveReject = <T, U>() => {
  let resolveMethod: (param: T) => void = () => '';
  let rejectMethod: (param: U) => void = () => '';

  const promise = new Promise<T>((resolve, reject) => {
    resolveMethod = resolve;
    rejectMethod = reject;
  });

  return { promise, resolve: resolveMethod, reject: rejectMethod };
};

export const parseMediaServerErrorKey = (
  key: string,
): MediaServerErrorKey | GeneralFileUploadErrorKey =>
  MediaServerErrorKeysTranslationMapping[key as MediaServerErrorKey]
    ? (key as MediaServerErrorKey)
    : GeneralErrorTranslation.key;

export const allSettled = (promisesArr: Array<Promise<any>>) =>
  Promise.all(
    promisesArr.map(
      promise =>
        new Promise<void>(resolve =>
          promise.then(() => resolve()).catch(() => resolve()),
        ),
    ),
  );

export const supportsMultipleFiles = (fileType: FileType) =>
  fileType === 'Image' || fileType === 'Video' || fileType === 'Gallery';
